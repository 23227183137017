import React from "react";
import Hero from "../../Hero";
import StranicaElement from "./StranicaElement";

const Stranica = ({ data }) => {
  return (
    <>
      <Hero
        name={data.name}
        straniceImg={`${data.folderSlika}/${data.heroSlika}`}
      />
      <div className="bg-dark-100 pb-20">
        <h3 className="text-center text-3xl sm:text-4xl md:text-5xl text-light-100 bg-dark-800 py-6">
          {data.tekst}
        </h3>
        <div className="container-outer pt-20">
          <div className="container grid grid-cols-1 max-w-xs sm:container sm:grid-cols-2 md:grid-cols-3 lg:max-w-[936px] gap-6">
            {data.proizvodi.map((el) => (
              <StranicaElement
                folder={data.folderSlika}
                data={el}
                key={Math.random()}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stranica;
