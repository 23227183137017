import React from "react";

const Hero = ({ name, folder, straniceImg }) => {
  return (
    <div
      style={
        folder
          ? {
              backgroundImage: `url(${require(`./${folder}/Images/Hero.jpg`)})`,
            }
          : {
              backgroundImage: `url(${require(`./Pictures/${straniceImg}`)})`,
            }
      }
      className="pb-40 py-48 bg-center bg-cover flex items-center justify-center mt-[-5rem] relative before:inset-0 before:bg-dark-900/30 before:absolute border-b-4 border-b-primary-100"
    >
      <h2 className="text-light-100 text-5xl sm:text-6xl md:text-7xl text-center py-6 px-10 bg-dark-900/30 z-10">
        {name}
      </h2>
    </div>
  );
};

export default Hero;
