import React from "react";
import PocetnaHero from "./PocetnaHero";
import NaciniGrejanja from "./NaciniGrejanja/NaciniGrejanja";
import Usluge from "./Usluge";

const Pocetna = () => {
  return (
    <>
      <PocetnaHero />
      <NaciniGrejanja />
      <Usluge />
    </>
  );
};

export default Pocetna;
