import React from "react";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="container-outer">
        <div className="container flex flex-col items-center justify-between lg:flex-row lg:items-start py-4 gap-4">
          <div className="flex flex-col items-center justify-center">
            <Link to="/">
              <img
                src={require("./Pictures/Logo.png")}
                alt="Logo"
                className="h-24 sm:h-32"
              />
            </Link>
            <p className="text-primary-100 font-semibold text-xl text-center lg:text-left">
              ENERGOPROMET 018 DOO
            </p>
          </div>

          <div className="border border-primary-100 w-32 self-center lg:h-20 lg:w-0 lg:translate-y-[-2rem]"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl lg:text-xl mb-2">Telefon</p>
            <div className="flex flex-col">
              <a
                href="tel:018549601"
                className="hover:text-primary-100 transition-colors duration-300"
              >
                +018 549 601
              </a>
              <a
                href="tel:018540440"
                className="hover:text-primary-100 transition-colors duration-300"
              >
                +018 540 440
              </a>
              <a
                href="tel:018548311"
                className="hover:text-primary-100 transition-colors duration-300"
              >
                +018 548 311
              </a>
            </div>
          </div>

          <div className="border border-primary-100 w-32 self-center lg:h-20 lg:w-0 lg:translate-y-[-2rem]"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl lg:text-xl mb-2">Sertifikati</p>
            <div className="flex flex-col">
              <p>ISO 9001</p>
              <p>ISO 14001</p>
            </div>
          </div>

          <div className="border border-primary-100 w-32 self-center lg:h-20 lg:w-0 lg:translate-y-[-2rem]"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl lg:text-xl mb-2">Email</p>
            <a
              href="mailto:energopromet018@gmail.com"
              className="hover:text-primary-100 transition-colors duration-300"
            >
              energopromet018@gmail.com
            </a>
          </div>

          <div className="border border-primary-100 w-32 self-center lg:h-20 lg:w-0 lg:translate-y-[-2rem]"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl lg:text-xl mb-2">O Registru</p>

            <p>PIB: 112404114</p>
            <p>MB: 21666785</p>
          </div>
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default Header;
