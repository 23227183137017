import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";

const PocetnaHero = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${require("../Pictures/Main-background.jpg")})`,
      }}
      className="bg-center bg-cover container-outer py-40 mt-[-5rem]"
    >
      <div className="container flex flex-col items-center md:items-start">
        <div>
          <img
            src={require("../Pictures/Logo.png")}
            alt="Logo"
            className="h-32 sm:h-48 md:h-60"
          />
        </div>
        <div className="text-light-100 flex flex-col gap-8 items-center md:items-start">
          <div className="text-center md:text-start flex flex-col items-center md:items-start">
            <h1 className="font-bold text-4xl md:text-5xl mb-6">
              Energopromet 018
            </h1>
            <p className="text-xl md:text-2xl max-w-[30ch]">
              Stovariste uglja i proizvodnja betonskih blokova
            </p>
          </div>
          <div className="flex gap-6">
            <Link
              to="/kontakt"
              className="px-8 py-2 border-2 border-primary-200/40 bg-primary-200/40 rounded-full transition-colors duration-300 hover:bg-primary-200"
            >
              <div onClick={scrollToTop}>Kontakt</div>
            </Link>
            <AnchorLink
              href="#usluge"
              className="px-8 py-2 border-2 border-primary-200/40 rounded-full transition-colors duration-300 hover:bg-primary-200"
            >
              Usluge
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PocetnaHero;
