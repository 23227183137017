import React from "react";
import Hero from "../Hero";

const Galerija = () => {
  return (
    <>
      <Hero name={"Galerija"} folder={"Galerija"} />
      <div className="container-outer py-20 bg-dark-100">
        <div className="container flex flex-col gap-8">
          <img
            src={require("./Images/slika1.jpg")}
            alt="slika1"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika2.jpg")}
            alt="slika2"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika3.jpg")}
            alt="slika3"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika4.jpg")}
            alt="slika4"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika5.jpg")}
            alt="slika5"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika6.jpeg")}
            alt="slika6"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika7.jpeg")}
            alt="slika7"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika8.jpg")}
            alt="slika8"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika9.jpg")}
            alt="slika9"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika10.jpg")}
            alt="slika10"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika11.jpg")}
            alt="slika11"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika12.jpg")}
            alt="slika12"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika13.jpg")}
            alt="slika13"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika14.jpg")}
            alt="slika14"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika15.jpg")}
            alt="slika15"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika16.jpg")}
            alt="slika16"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika17.jpg")}
            alt="slika17"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika18.jpg")}
            alt="slika18"
            className="aspect-video object-cover object-center"
          />
          <img
            src={require("./Images/slika19.jpg")}
            alt="slika19"
            className="aspect-video object-cover object-center"
          />
        </div>
      </div>
    </>
  );
};

export default Galerija;
