import React from "react";

const StranicaElement = ({ folder, data }) => {
  return (
    <div className="grid grid-rows-2 items-center border border-light-900 gap-4s max-h-80">
      <div className="w-[100%] h-[100%] bg-light-900s">
        <img
          src={require(`../../Pictures/${folder}/${data.slika}`)}
          alt={data.vrsta}
          className="object-covers object-center w-full h-full"
        />
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-2 text-light-200 text-center p-4 h-full">
        <div className="font-regular text-xl uppercase">{data.naziv}</div>
        <div className="font-regular text-xl">{data.vrsta}</div>
        <div className="font-regular text-xl text-primary-400">
          {data.dostupnost}
        </div>
      </div>
    </div>
  );
};

export default StranicaElement;
