import React from "react";
import { Link } from "react-router-dom";

const Nacin = ({ data }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Link
      to={data.link}
      className="w-[45%] lg:w-1/5 sm:aspect-video aspect-square lg:aspect-square overflow-hidden border-dark-900 border"
    >
      <div
        style={{
          backgroundImage: `url(${require(`../../Pictures/${data.folderSlika}/${data.slika}`)})`,
        }}
        className="w-full h-full bg-center bg-cover flex justify-center items-center"
        onClick={scrollToTop}
      >
        <div className="text-white text-xl sm:text-2xl text-center bg-dark-100/50 px-4 py-2">
          {data.name}
        </div>
      </div>
    </Link>
  );
};

export default Nacin;
