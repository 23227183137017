import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Pocetna from "./components/Pocetna/Pocetna";
import ONama from "./components/ONama/ONama";
import Galerija from "./components/Galerija/Galerija";
import NacinPlacanja from "./components/NacinPlacanja/NacinPlacanja";
import data from "./components/Data";
import Stranica from "./components/Pocetna/NaciniGrejanja/Stranica";
import Footer from "./components/Footer";
import Kontakt from "./components/Kontakt/Kontakt";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path={"/"} element={<Pocetna />} />
        <Route path={"/o-nama"} element={<ONama />} />
        <Route path={"/galerija"} element={<Galerija />} />
        <Route path={"/placanje"} element={<NacinPlacanja />} />
        <Route path={"/kontakt"} element={<Kontakt />} />
        {data.map((el) => (
          <Route
            path={el.link}
            element={<Stranica data={el} key={Math.random()} />}
          />
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
