import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  //   const links = document.querySelectorAll("li");
  //   links.forEach((link) => {
  //     link.onclick = () => {
  //       links.forEach((e) => {
  //         e.className.remove("text-light-900");
  //       });
  //       link.className.add("text-light-900");
  //       console.log(link.innerHTML);
  //     };
  //   });
  const [isClosed, setIsClosed] = useState(true);

  const handleIsClosed = () => {
    setIsClosed(!isClosed);
  };

  const handleClose = () => {
    setIsClosed(true);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container-outer bg-dark-900/50 z-20 relative h-20 flex justify-center items-center">
      <div
        className={`md:block container items-center flex justify-center ${
          isClosed
            ? "hiddens"
            : "flex flex-col fixed h-full left-0 top-0 px-12 pt-16 gap-12 w-full text-xl font-medium bg-dark-100"
        }`}
      >
        <div
          className={`h-7 md:hidden ${isClosed ? "block" : "hidden"}`}
          onClick={handleIsClosed}
        >
          <img
            src={require("./Pictures/Hamburger.png")}
            alt="Hamburger Menu"
            className="h-full"
          />
        </div>
        <ul
          className={`md:flex md:text-xl md:justify-center md:gap-10 text-light-100 md:font-light ${
            isClosed ? "hidden" : "text-3xl text-center flex flex-col gap-6"
          } `}
        >
          <div className={`h-6 md:hidden mx-auto `} onClick={handleClose}>
            <img
              src={require("./Pictures/Close.png")}
              alt="Hamburger Menu"
              className="h-full"
            />
          </div>
          <Link to="/" onClick={scrollToTop}>
            <li
              className="hover:text-primary-100 transition-[color] duration-300"
              onClick={handleClose}
            >
              Početna
            </li>
          </Link>
          <Link to="o-nama" onClick={scrollToTop}>
            <li
              className="hover:text-primary-100 transition-[color] duration-300"
              onClick={handleClose}
            >
              O nama
            </li>
          </Link>
          <Link to="galerija" onClick={scrollToTop}>
            <li
              className="hover:text-primary-100 transition-[color] duration-300"
              onClick={handleClose}
            >
              Galerija
            </li>
          </Link>
          <Link to="placanje" onClick={scrollToTop}>
            <li
              className="hover:text-primary-100 transition-[color] duration-300"
              onClick={handleClose}
            >
              Način plaćanja
            </li>
          </Link>
          <Link to="kontakt" onClick={scrollToTop}>
            <li
              className="hover:text-primary-100 transition-[color] duration-300"
              onClick={handleClose}
            >
              Kontakt
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
