const data = [
  {
    name: "Ugalj",
    slika: "Hero.jpg",
    heroSlika: "Hero.jpg",
    folderSlika: "Ugalj",
    tekst: "Vrste uglja",
    link: "/ugalj",
    proizvodi: [
      {
        slika: "slika1.jpg",
        naziv: "Ugalj",
        vrsta: "Pljevlja",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika2.jpg",
        naziv: "Ugalj",
        vrsta: "Stanari",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika3.jpg",
        naziv: "Ugalj",
        vrsta: "Miljevina",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika4.png",
        naziv: "Ugalj",
        vrsta: "Pljevlja Džak-30kg",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika4.png",
        naziv: "Ugalj",
        vrsta: "Stanari Džak-30kg",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika4.png",
        naziv: "Ugalj",
        vrsta: "Miljevina Džak-30kg",
        dostupnost: "Dostupno",
      },
    ],
  },
  {
    name: "Betonski Blokovi",
    slika: "Glavna.jpg",
    heroSlika: "Glavna.jpg",
    folderSlika: "Blokovi",
    tekst: "Vrste Blokova",
    link: "/blokovi",
    proizvodi: [
      {
        slika: "slika1.jpg",
        naziv: "Betonski Blok:9",
        vrsta: "Dimenzije (9x19x39)",
        dostupnost: "",
      },
      {
        slika: "slika2.jpg",
        naziv: "Betonski Blok:14",
        vrsta: "Dimenzije (14x19x39)",
        dostupnost: "",
      },
      {
        slika: "slika3.jpg",
        naziv: "Betonski Blok:20",
        vrsta: "Dimenzije (19x19x39)",
        dostupnost: "",
      },
      {
        slika: "slika4.jpg",
        naziv: "Termo-Betonski Blok:25",
        vrsta: "Dimenzije (24x19x39)",
        dostupnost: "",
      },
      {
        slika: "slika5.jpg",
        naziv: "Prelazni E1 Blok:25",
        vrsta: "Dimenzije (25x19x41)",
        dostupnost: "",
      },
      {
        slika: "slika6.jpg",
        naziv: "Punioci Blok:25",
        vrsta: "Dimenzije (24x19x39)",
        dostupnost: "",
      },
      {
        slika: "slika7.jpg",
        naziv: "Ugaoni Blok:25",
        vrsta: "Dimenzije (25x19x39)",
        dostupnost: "",
      },
      {
        slika: "slika8.jpg",
        naziv: "Ventilacioni H1 Blok:25",
        vrsta: "Dimenzije (25x19x41)",
        dostupnost: "",
      },
      {
        slika: "slika9.jpg",
        naziv: "Ventilacioni H2 Blok:25",
        vrsta: "Dimenzije (25x19x41)",
        dostupnost: "",
      },
      {
        slika: "slika10.jpg",
        naziv: "Ventilacioni-Dimnjački Blok:20",
        vrsta: "Dimenzije (19x19x39)",
        dostupnost: "",
      },
    ],
  },
  {
    name: "Drveni ogrev",
    slika: "Glavna.jpg",
    heroSlika: "Glavna.jpg",
    folderSlika: "Drveni",
    tekst: "Drveni Ogrev",
    link: "/drveni-ogrev",
    proizvodi: [
      {
        slika: "slika2.png",
        naziv: "",
        vrsta: "Tvrdo presovani briketi od bukve",
        dostupnost: "",
      },
      {
        slika: "slika2.png",
        naziv: "",
        vrsta: "Tvrdo presovani briketi od bukve",
        dostupnost: "",
      },
      {
        slika: "slika2.png",
        naziv: "",
        vrsta: "Tvrdo presovani briketi od bukve",
        dostupnost: "",
      },
    ],
  },
  {
    name: "Građevinski materijali",
    slika: "Hero.jpg",
    heroSlika: "Hero.jpg",
    folderSlika: "Gradjevinski",
    tekst: "Vrste Gradjevinskih materijala",
    link: "/gradjevinski-materijali",
    proizvodi: [
      {
        slika: "slika2.jpg",
        naziv: "Pesak",
        vrsta: "Jedinica",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika3.jpg",
        naziv: "Šoder",
        vrsta: "",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika4.jpeg",
        naziv: "Pesak",
        vrsta: "Dunavac",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika1.jpg",
        naziv: "Cement",
        vrsta: "",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika5.png",
        naziv: "Pesak",
        vrsta: "Jedinica Džak",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika5.png",
        naziv: "Šoder",
        vrsta: "Džak",
        dostupnost: "Dostupno",
      },
      {
        slika: "slika5.png",
        naziv: "Pesak Dunavac",
        vrsta: "Džak",
        dostupnost: "Dostupno",
      },
    ],
  },
];

export default data;
