import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="container-outer bg-dark-100 border-t-4 border-t-primary-100">
      <div className="container flex flex-col items-center gap-4 py-20">
        <div className="h-40">
          <Link to="/">
            <img
              src={require("./Pictures/Logo.png")}
              alt="Logo"
              className="h-full"
              onClick={scrollToTop}
            />
          </Link>
        </div>
        <div className="text-light-100 flex flex-col md:flex-row  gap-12 items-start">
          <div>
            <p className="text-2xl font-semibold border-l-2 border-l-primary-100 ps-4 mb-4">
              Radno vreme
            </p>
            <div className="ps-4 flex flex-col gap-2 text-lg">
              <p>Radnim danima: 08:00-16:00</p>
              <p>Subotom: 08:00-15:00</p>
              <p>Nedeljom: Ne radimo</p>
            </div>
          </div>
          <div className="md:text-right">
            <p className="text-2xl font-semibold border-l-2 border-l-primary-100 md:border-l-0 ps-4 md:border-r-2 md:border-r-primary-100 md:pe-4 mb-4">
              Kontakt
            </p>
            <div className="pe-4 flex flex-col gap-2 text-lg">
              <p>
                <a
                  href="tel:018549601"
                  className="hover:text-primary-100 transition-colors duration-300"
                >
                  +018 549601
                </a>
              </p>
              <p>
                <a
                  href="tel:018540440"
                  className="hover:text-primary-100 transition-colors duration-300"
                >
                  +018 540 440
                </a>
              </p>
              <p>
                <a
                  href="tel:018548311"
                  className="hover:text-primary-100 transition-colors duration-300"
                >
                  +018 548 311
                </a>
              </p>
              <p>
                <a
                  href="mailto:energopromet018@gmail.com"
                  className="hover:text-primary-100 transition-colors duration-300"
                >
                  energopromet018@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
