import React from "react";
import Hero from "../Hero";

const Kontakt = () => {
  return (
    <div className="bg-dark-100 text-dark-100">
      <Hero name={"Kontakt"} folder={"Kontakt"} />
      <div className="container-outer">
        <div className="container flex flex-col justify-center items-center pt-10">
          <div className="text-3xl mb-8">Kontaktirajte nas</div>
          <form
            action="https://formsubmit.co/energopromet018@gmail.com"
            method="POST"
            className="flex flex-col w-full max-w-[24rem]"
          >
            <label htmlFor="ime" className="text-center text-lg text-light-100">
              IME I PREZIME
            </label>
            <input
              id="ime"
              type="text"
              name="Name"
              placeholder=""
              required
              className="border border-dark-100 px-4 py-2 placeholder-dark-100 mb-6 mt-1 outline-none focus:border-b-2 focus:border-b-primary-100"
            />
            <label
              htmlFor="email"
              className="text-center text-lg text-light-100"
            >
              EMAIL ADRESA
            </label>
            <input
              id="email"
              type="email"
              name="Email"
              placeholder=""
              required
              className="border border-dark-100 px-4 py-2 placeholder-dark-800 mb-6 mt-1 outline-none focus:border-b-2 focus:border-b-primary-100"
            />
            <input type="hidden" name="_subject" value="New submission!" />
            <input type="hidden" name="_captcha" value="false" />
            <label
              htmlFor="poruka"
              className="text-center text-lg text-light-100"
            >
              POSALJITE NAM PORUKU
            </label>
            <textarea
              name="Idea"
              id="poruka"
              placeholder=""
              required
              className="border border-dark-100 px-4 py-2 placeholder-dark-800 mb-6 mt-1 outline-none focus:border-b-2 focus:border-b-primary-100"
            ></textarea>
            <button
              type="submit"
              className="bg-primary-100 hover:bg-primary-200 transition-colors duration-300 py-2 px-8 text-light-100 font-semibold text-lg w-max mx-auto"
            >
              Send
            </button>
          </form>
        </div>
      </div>
      <div className="mt-20 mb-40">
        <iframe
          title="Mapa"
          src={
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2249.8785043809376!2d21.974111236524603!3d43.30469190191949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4755b06edd08dce3%3A0x2e09c50c1e43eff9!2sEnergopromet!5e0!3m2!1sen!2srs!4v1701979490584!5m2!1sen!2srs"
          }
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="aspect-square lg:aspect-[20/6] w-full"
        ></iframe>
      </div>
      <div className="container-outer">
        <div className="container flex flex-col justify-center items-centers lg:flex-row gap-20 lg:gap-4 pb-40">
          <div className="bg-[#d4d4d2] relative w-full sm:w-1/2 lg:w-[30%] px-16 py-20 text-dark-100 rounded-xl mx-auto">
            <div className="w-28 absolute left-1/2 translate-x-[-50%] top-0 translate-y-[-50%]">
              <img
                src={require("./Images/slika1.png")}
                alt="Slika"
                className="w-full border-2 border-dark-900 rounded-full"
              />
            </div>
            <div>
              <div className="text-3xl font-light text-center mb-6">Adresa</div>
              <div className="border border-primary-100"></div>
              <div className=" text-lg text-center mt-6">
                91 Bulevar Svetog Cara Konstantina, 18110, Nikola Tesla,
                Nišavski okrug
              </div>
            </div>
          </div>
          <div className="bg-[#d4d4d2] relative w-full sm:w-1/2 lg:w-[30%] px-16 py-20 text-dark-100 rounded-xl mx-auto">
            <div className="w-28 absolute left-1/2 translate-x-[-50%] top-0 translate-y-[-50%]">
              <img
                src={require("./Images/slika2.png")}
                alt="Slika"
                className="w-full border-2 border-dark-900 rounded-full"
              />
            </div>
            <div>
              <div className="text-3xl font-light text-center mb-6">
                Telefon
              </div>
              <div className="border border-primary-100"></div>
              <div className=" text-lg text-center mt-6">
                <p>
                  <a
                    href="tel:018549601"
                    className="hover:text-primary-100 transition-colors duration-300"
                  >
                    +018 549601
                  </a>
                </p>
                <p>
                  <a
                    href="tel:018540440"
                    className="hover:text-primary-100 transition-colors duration-300"
                  >
                    +018 540 440
                  </a>
                </p>
                <p>
                  <a
                    href="tel:018548311"
                    className="hover:text-primary-100 transition-colors duration-300"
                  >
                    +018 548 311
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#d4d4d2] relative w-full sm:w-1/2 lg:w-[30%] py-20 text-dark-100 rounded-xl mx-auto">
            <div className="w-28 absolute left-1/2 translate-x-[-50%] top-0 translate-y-[-50%]">
              <img
                src={require("./Images/slika3.png")}
                alt="Slika"
                className="w-full border-2 border-dark-900 rounded-full"
              />
            </div>
            <div>
              <div className="text-3xl font-light text-center mb-6">Email</div>
              <div className="border border-primary-100 mx-16"></div>
              <div className="text-md text-center mt-6">
                <a
                  href="mailto:energopromet018@gmail.com"
                  className="hover:text-primary-100 transition-colors duration-300"
                >
                  energopromet018@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
