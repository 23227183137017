import React from "react";
import Hero from "../Hero";

const NacinPlacanja = () => {
  return (
    <>
      <Hero name={"Način plaćanja"} folder={"NacinPlacanja"} />
      <div className="container-outer py-20 bg-dark-100 text-light-100">
        <div className="container flex flex-col md:flex-row md:items-start gap-12 md:gap-[3.333%]">
          <div className="flex flex-col items-center justify-center w-full md:w-[30%] shrink-0 gap-6">
            <div className="text-2xl text-cente">Gotovinsko plaćanje</div>
            <img
              src={require("./Images/slika1.png")}
              alt="Gotovinsko"
              className="h-40"
            />
            <div className="text-lg text-center">
              Plaćanje u gotovini u našim prostorijama
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full md:w-[30%] shrink-0 gap-6">
            <div className="text-2xl text-center">Plaćanje preko racuna</div>
            <img
              src={require("./Images/slika2.png")}
              alt="Racun"
              className="h-44"
            />
            <div className="text-lg text-center">
              Svoju porudžbinu možete platiti direktnom uplatom na račun
              Energopromet. Plaćanje možete izvršiti standardnom uplatnicom u
              bilo kojoj pošti ili banci, ili putem Interneta (web banking).
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full md:w-[30%] shrink-0 gap-6">
            <div className="text-2xl text-center">
              Plaćanje platnim i kreditnim karticama
            </div>
            <img
              src={require("./Images/slika3.png")}
              alt="Karticno"
              className="h-40"
            />
            <div className="text-lg text-center">
              Mogućnost plaćanja platnim i kreditnim karticama. (DINACARD, VISA,
              MAESTRO, MASTERCARD)
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default NacinPlacanja;
