import React from "react";

const Usluge = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${require("../Pictures/Usluge-background.jpg")})`,
      }}
      className="bg-center bg-cover pb-20 pt-40 relative"
      id="usluge"
    >
      <div className="">
        <h2 className="text-light-100 bg-dark-800 text-center text-2xl sm:text-3xl md:text-4xl mb-20 py-6 px-6 border-t-4 border-t-primary-100 absolute top-0 left-0 right-0">
          Usluge koje vam pružamo
        </h2>
        <div className="italic font-bold text-primary-200 text-3xl sm:text-4xl flex flex-col gap-8 items-center justify-center text-center container">
          <p className="bg-dark-900/60 py-4 px-6">
            -Mogućnost kupovine na odloženo plaćanje
          </p>
          <p className="bg-dark-900/60 py-4 px-6">
            -Prevoz po dogovoru do kupca
          </p>
          <p className="bg-dark-900/60 py-4 px-6">-Sve na jednom mestu</p>
          <p className="bg-dark-900/60 py-4 px-6">-Brzo, lako, efikasno</p>
        </div>
      </div>
    </div>
  );
};

export default Usluge;
