import React from "react";
import data from "../../Data";
import Nacin from "./Nacin";

const NaciniGrejanja = () => {
  return (
    <div className="container-outer pb-32 pt-48 bg-dark-100 relative">
      <h2 className="text-light-100 bg-dark-800 text-center text-2xl sm:text-3xl md:text-4xl mb-20 py-6 px-6 border-t-4 border-t-primary-100 absolute top-0 left-0 right-0">
        Mi unosimo toplinu u vaš dom
      </h2>
      <div className="container flex flex-wrap justify-center gap-[10%] gap-y-12 md:gap-x-[6.666%]">
        {data.map((el) => (
          <Nacin key={Math.random()} data={el} />
        ))}
      </div>
    </div>
  );
};

export default NaciniGrejanja;
