import React from "react";
import Hero from "../Hero";

const ONama = () => {
  return (
    <div className="bg-dark-100 text-light-100">
      <Hero name={"O nama"} folder={"ONama"} />
      <div>
        <div className="container-outer py-20 lg:py-32 bg-dark-800">
          <div className="container text-center text-xl font-light lg:px-28">
            Energopromet je firma koja se bavi maloprodajom i velikoprodajom
            ogreva - uglja, šodera, peska, cementa i proizvodnjom betonskih
            blokova. Naša primarna misija je dalje poboljšanje ponude po
            najpristupačnijim cenama, zatim poslovna saradnja sa dokazanim
            proizvođačima ogreva, čime čuvamo stečeno poverenje kupaca.
          </div>
        </div>
        <div className="container-outer py-20">
          <div className="container text-xl font-light flex flex-col md:flex-row gap-4 items-center lg:px-20">
            <div className="md:w-1/2 order-1 md:order-[0] text-center md:text-left">
              Otvoreni smo za svaki vid saradnje,ako imate dilemu koji ugalj
              izabrati,za koju vrstu peći,koja vam je količina potrebna,koje su
              karakteristike uglja ili bilo koja druga informacija vezana za
              ugalj slobodno se javite stojimo vam na raspolaganju.
            </div>
            <div className="md:w-1/2 flex items-center justify-center">
              <img
                src={require("./Images/slika1.png")}
                alt="Ugalj"
                className="h-96 object-contain"
              />
            </div>
          </div>
        </div>
        <div className="container-outer py-20 bg-dark-800">
          <div className="container text-xl font-light flex flex-col md:flex-row gap-4 items-center lg:px-20">
            <div className="md:w-1/2 flex items-center justify-center">
              <img
                src={require("./Images/slika2.png")}
                alt="Ugalj"
                className="h-96 object-contain"
              />
            </div>
            <div className="md:w-1/2 text-center md:text-left">
              <div>
                Dugi niz godina se bavimo proizvodnjom betonskih blokova za
                gradjevinu, a pored proizvodnje vrsimo i prevoz gradjevinskog
                materijala do kupca.Betoonski blokovi iz nase ponude su idealni
                za zidanje tamo gde je potrebno:
              </div>
              <div>-Izdrzljivost</div>
              <div>-Dobra izolacija</div>
              <div>-Dugotrajnost</div>
            </div>
          </div>
        </div>
        <div className="container-outer py-20">
          <div className="container text-xl font-light flex flex-col md:flex-row gap-4 items-center lg:px-20">
            <div className="md:w-1/2 order-1 md:order-[0] text-center md:text-left">
              Drveni briket odličnog kvaliteta tvrdopresovan bez lepka, ima
              veliku kalorisku vrednost i brzo postiže veliku temperaturu.
            </div>
            <div className="md:w-1/2 flex items-center justify-center">
              <img
                src={require("./Images/slika3.png")}
                alt="Ugalj"
                className="h-96 object-contain"
              />
            </div>
          </div>
        </div>
        <div className="container-outer py-20 bg-dark-800">
          <div className="container text-xl font-light flex flex-col md:flex-row gap-4 items-center lg:px-20 ">
            <div className="md:w-1/2 flex items-center justify-center">
              <img
                src={require("./Images/slika4.png")}
                alt="Ugalj"
                className="h-96 object-contain"
              />
            </div>
            <div className="md:w-1/2">
              <div>
                Šoder ima široku primenu, posebno prilikom proizvodnje betona,
                posipanja puteva, prilikom pravljenja malter i mnoge druge
                primene. Pesak za gradjevinske radove, pesak u džakovima, za
                veće količine kupljenog peska obezbeđen prevoz. Kompozitni
                cement sa mešanim dodatkom letećeg pepela i krečnjaka koji se
                odlikuje umerenim priraštajem čvrstoće.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ONama;
